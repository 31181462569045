import * as styles from "./about.module.scss";

import Banner from "../components/Banner";
import Layout from "../components/Layout";
import React from "react";
import SectionHeader from "../components/SectionHeader";
import SeoHelmet from "../components/SeoHelmet";
import clsx from "clsx";

export default function About() {
  return (
    <Layout>
      <SeoHelmet title="About Us" />
      <Banner
        title="About Us"
        subtitle={
          <span>
            Not perfect,
            <br />
            but working on it
          </span>
        }
        image="about"
      />
      <SectionHeader anchor="about-us">
        <h2>
          Repeatable methods,
          <br />
          repeatable results
        </h2>
      </SectionHeader>
      <div className={styles.pageContent}>
        <p>
          “Lithic Tech was founded in Portland, Oregon by experienced software
          engineering leaders with a track record of successful businesses, happy teams,
          and delighted customers. Our success is a result of our unique processes,
          patterns, and convictions.
        </p>
        <p>
          “Deep expertise in applying the best tools and methods from eXtreme
          Programming and Lean software development enables us to turn struggling
          projects around in rapidly, and deliver new projects quickly and reliably.
          We’ve validated our approach on over a dozen teams at a dozen companies, and
          we keep getting results.
        </p>
        <p>
          “We practice test-driven-development, build extensive automated tests into all
          our software, and take a no-known-bugs practice. Baking quality into our
          processes leads to low-risk, easily-changed software that pays for itself in
          ease of maintenance and enhancement. We find that it actually becomes faster
          over time to build new features and make changes to well-tested code.
        </p>
        <p>
          “Working this way requires a skillset we’ve honed over many years. It’s what
          makes Lithic unique. Our clients and employees both get to experience the
          epiphany that comes with developing software multiple times more effectively
          than they have in the past. It makes everyone happier, healthier, and more
          successful.
        </p>
        <p>- Rob Galanakis and Matt Greensmith, Founders</p>
      </div>
      <SectionHeader className={styles.sectionSpacingT} anchor="mission">
        <h2>Lithic's Mission</h2>
      </SectionHeader>
      <div className={styles.pageContent}>
        <p>
          Finding better ways to build software is not just about being able to create
          more value for our clients. As professionals, our sense of belonging and
          fulfillment is, for better or worse, deeply tied to our work lives. Simply
          put: we believe that by improving how software teams work, we will have
          positive impacts on the rest of their lives.
        </p>

        <ul className={styles.about}>
          <li>
            What if you could delight your coworkers by solving their problems with your
            every-day work, not just what you hack together on the side?
          </li>
          <li>
            How much more inclusive and welcoming would your team be if conflict wasn’t
            on the critical path of getting work done?
          </li>
          <li>
            Who would cringe at the idea of “adding process,” if the processes you have
            works to everyone’s benefit?
          </li>
          <li>
            Why would employees look to go elsewhere if they feel their current job is
            rewarding and helping them grow?
          </li>
          <li>
            What problems would you solve in your community, if you didn’t need a
            million dollars in capital to build a solution?
          </li>
        </ul>

        <p className={clsx(styles.sectionSpacingB)}>
          These problems are ever-present in technology companies, and they are missing
          the solution right in front of them: improving how you build software is worth
          more than all the La Croix and catered lunches in the world. This is a simple
          idea, but putting it into practice is not. Helping our clients improve how
          they build software will, we are convinced, have positive impact on bottom
          lines, their employees, and — ultimately — our communities.
        </p>
      </div>
    </Layout>
  );
}
